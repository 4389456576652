"use client";
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, isSupported } from "firebase/messaging";
import { debugLog } from "@/services/logging";

export const firebaseConfig = {
    apiKey: "AIzaSyCU2Pg07ohbqnxlX2vGEeRh8UZvrVeh7zE",
    authDomain: "bazaarroom-360516.firebaseapp.com",
    projectId: "bazaarroom-360516",
    storageBucket: "bazaarroom-360516.appspot.com",
    messagingSenderId: "563543409164",
    appId: "1:563543409164:web:2466c29f8fecb121836429",
    measurementId: "G-9GR6MM2ZND"
  };

  const firebaseApp = initializeApp(firebaseConfig);
	export const messaging =
		typeof window !== "undefined" ? getMessaging(firebaseApp) : null;

  // export const messaging = (async () => {
  //   try {
  //     const isSupportedBrowser = await isSupported();
  //     if (isSupportedBrowser) {
  //       return getMessaging(firebaseApp);
  //     }
  //     debugLog("Firebase is not supported in this browser");
  //     return null;
  //   } catch (err) {
  //     debugLog(err);
  //     return null;
  //   }
  // })();

  export const getOrRegisterServiceWorker = () => {
    if (
      "serviceWorker" in navigator &&
      typeof window.navigator.serviceWorker !== "undefined"
    ) {
      return window.navigator.serviceWorker
        .getRegistration("/firebase-push-notification-scope")
        .then((serviceWorker) => {
          if (serviceWorker) return serviceWorker;
          return window.navigator.serviceWorker.register(
            "/firebase-messaging-sw.js",
            {
              scope: "/firebase-push-notification-scope",
            }
          );
        });
    }
    throw new Error("The browser doesn`t support service worker.");
  };

  // getFirebaseToken function generates the FCM token
export const getFirebaseToken = async () => {
    try {
      const messagingResolve = await messaging;
      if (messagingResolve) {
        return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
          return Promise.resolve(
            getToken(messagingResolve, {
              vapidKey: "BNt2ovTEg8Yl_RLPFKdtub4hpl_EiHzDam-vz1zbqYVhh7Hpd57HUHD-88W_H72MT2RbspaMj85DAnvXr_nC8gI",
              serviceWorkerRegistration,
            })
          );
        });
      }
    } catch (error) {
      debugLog("An error occurred while retrieving token. ", error);
    }
  };

  export const requestPermission = () => {

    debugLog("Requesting User Permission......");
    if(typeof Notification !== 'undefined'){
    Notification.requestPermission().then((permission) => {

      if (permission === "granted") {

        debugLog("Notification User Permission Granted.");
       getFirebaseToken()
          .then((currentToken) => {

            if (currentToken) {
              // debugLog('Client Token: ', currentToken);
              document.cookie = `fcm_token=${currentToken}`


            } else {

              debugLog('Failed to generate the app registration token.');
            }
            return permission
          })
          .catch((err) => {

            debugLog('An error occurred when requesting to receive the token.', err);
          });
      } else {

        debugLog("User Permission Denied.");
        return permission;
      }
    });
  }

  }

requestPermission();

  const UrlFirebaseConfig = new URLSearchParams(
    {
      apiKey: firebaseConfig.apiKey,
      authDomain: firebaseConfig.authDomain,
      projectId: firebaseConfig.projectId,
      storageBucket: firebaseConfig.storageBucket,
      messagingSenderId: firebaseConfig.messagingSenderId,
      appId: firebaseConfig.appId,
    }.toString()
  );

  const swUrl = `http://localhost:3000/firebase-messaging-sw.js?${UrlFirebaseConfig}`;