import { env } from "@/env.mjs";

export function debugLog(message?: any, ...optionalParams: any[]) {
    try{
        if(isThisTheProductionBrowser()){
            return;
        }

        console.log(message, ...optionalParams);

    }catch(e){

    }
}



function isThisTheProductionBrowser(){
    return typeof window !== 'undefined' && env.ENV_NAME === "prod";
}