"use client";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
	Toast,
	ToastAction,
	ToastDescription,
	ToastProvider,
	ToastTitle,
} from "@/components/ui/toast";
import { Toaster } from "@/components/ui/toaster";
import { useToast } from "@/components/ui/use-toast";
import { MessageType, Persistence } from "@/lib/api/chime";
import MessagingService from "@/services/messaging";
import { firebaseConfig, messaging, requestPermission } from "@/utils/firebase";
import { BellIcon } from "@radix-ui/react-icons";
import { initializeApp } from "firebase/app";
import {
	MessagePayload,
	getMessaging,
	getToken,
	onMessage,
} from "firebase/messaging";
import localforage from "localforage";
import { Bell, Terminal } from "lucide-react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import React, { useContext, useRef } from "react";
import { ReactNode, useEffect, useState } from "react";
import { debugLog } from "@/services/logging";

type Props = {
	children: ReactNode;
};

export type NotificationStateContextType = {
	newMessage: boolean;
	unread: any[];
	activeUserChannel: any;
	setActiveUserChannel: (value: React.SetStateAction<any>) => void;
	setUnread: (value: React.SetStateAction<any[]>) => void;
	setNewMessage: (value: React.SetStateAction<boolean>) => void;
};

const NotificationServiceContext =
	React.createContext<NotificationStateContextType | null>(null);

export default function NotificationProvider({ children }: Props) {
	const { toast } = useToast();
	const router = useRouter();
	const [showBanner, setShowBanner] = useState(false);
	const [activeUserChannel, setActiveUserChannel] = useState<any>({});
	const [newMessage, setNewMessage] = useState(false);
	const [unread, setUnread] = useState<any[]>([]);
	const unreadRef = useRef(unread);
	const activeRef = useRef(activeUserChannel);

	const session = useSession();
	const isAuthenticated = session.status == "authenticated" ? true : false;

	const [messagingService] = useState(() => new MessagingService());

	useEffect(() => {
		unreadRef.current = unread;
		activeRef.current = activeUserChannel;
	});

	useEffect(() => {
		debugLog("message service effect 2");

		if (!isAuthenticated) return;

		// Start messaging service
		messagingService.connect(session.data?.user.id!);

		return () => {
			messagingService.close();
		};
	}, [isAuthenticated]);

	const newMessagesProcessor = async (message: any) => {
		const messageType = message?.headers["x-amz-chime-event-type"];
		const record = JSON.parse(message?.payload);
		debugLog("Incoming Message", record);
		if (
			message?.headers["x-amz-chime-message-type"] == MessageType.STANDARD
		) {
			if (
				messageType === "CREATE_CHANNEL_MESSAGE" &&
				record.Persistence === Persistence.PERSISTENT
			) {
				if (activeRef.current.ChannelArn !== record?.ChannelArn) {
					const findMatch = unread.find(
						(chArn) => chArn === record.ChannelArn
					);
					if (findMatch) return;
					const newUnreads = [...unreadRef.current, record.ChannelArn];
					setUnread(newUnreads);
					setNewMessage(true);
				}
			}
		}
	};

	useEffect(() => {
		if (!isAuthenticated) return;

		messagingService.subscribeToMessageUpdate(newMessagesProcessor);
		return () => {
			messagingService.unsubscribeFromMessageUpdate(newMessagesProcessor);
		};
	}, [messagingService, isAuthenticated]);

	// This is self invoking function that listen of the notification
	const onMessageListener = async () => {
		const messagingResolve = await messaging;
		if (messagingResolve) {
			onMessage(messagingResolve, (payload: MessagePayload) => {
				toast({
					title: payload.notification?.title,
					description: payload.notification?.body,
					action: (
						<ToastAction
							altText="Open"
							onClick={() => router.push("/chat")}>
							Open
						</ToastAction>
					),
					duration: 6000,
				});
			});
		}
	};

	useEffect(() => {
		if (
			"Notification" in window &&
			window.Notification?.permission === "granted"
		) {
			requestPermission();
		} else if (
			"Notification" in window &&
			window.Notification?.permission === "denied"
		) {
			setShowBanner(true);
			// toast({
			// 	title: "Notifications",
			// 	description:
			// 		"The app needs permission to enable push notifications",
			// 	duration: 5000,
			// 	action: <ToastAction altText="Enable">Enable</ToastAction>,
			// });
		}
	}, []);

	useEffect(() => {
		const unsubscribe = onMessageListener();
		return () => {
			unsubscribe.catch((err) => debugLog("failed: ", err));
		};
	}, []);

	const notificationStateValue = {
		newMessage,
		setNewMessage,
		unread,
		setUnread,
		activeUserChannel,
		setActiveUserChannel,
	};
	return (
		<NotificationServiceContext.Provider value={notificationStateValue}>
			{showBanner && (
				<div className="flex flex-col justify-center items-center gap-2 text-center text-[0.75rem] px-4 border py-2 sm:text-base sm:flex-row">
					<Bell className="text-blue-500 h-5 w-5 flex-shrink-0" /> 
					<p>The app needs permission to enable push notifications. You can enable
					notifications by visiting the site settings in the browser</p>
					{/* <a
						className="w-full no-underline py-3.5 bg-[rgb(255, 255, 255)]/80 hover:text-orange-100"
						href="#"
						onClick={requestPermission}>
						<span className="inline-block w-18 leading-7 px-4 bg-blue-100 h-[28px] rounded-sm">
							Enable
						</span>
					</a> */}
				</div>
			)}

			{children}
		</NotificationServiceContext.Provider>
	);
}

export const useNotificationState = () => {
	const context = useContext(NotificationServiceContext);

	if (!context) {
		throw new Error(
			"useNotificationService must be used within NotificationServiceContextt"
		);
	}

	return context;
};
