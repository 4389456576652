import {
	createMemberArn,
	getAWSCredential,
	getMessagingSessionEndpoint,
} from "@/lib/api/chime";
import { ChimeSDKMessaging } from "@aws-sdk/client-chime-sdk-messaging";
import {
	ConsoleLogger,
	DefaultMessagingSession,
	LogLevel,
	Message,
	MessagingSessionConfiguration,
	MessagingSessionObserver,
} from "amazon-chime-sdk-js";
import { v4 as uuidv4 } from "uuid";

class MessagingService {
	[x: string]: any;
	constructor() {
		this._session;
		this.sessionId = uuidv4();
		this._logger = new ConsoleLogger("Bazaar Chat", LogLevel.INFO);
		this._messageUpdateCallbacks = [];
	}

	messageObserver: MessagingSessionObserver = {
		messagingSessionDidStart: () => {
			console.log("Messaging Connection started!", "");
		},
		messagingSessionDidStartConnecting: (reconnecting) => {
			console.log("Messaging Connection connecting", reconnecting);
		},
		messagingSessionDidStop: (event) => {
			console.log("Messaging Connection received DidStop event", event);
		},
		messagingSessionDidReceiveMessage:
			this.handleMessagingSessionDidReceiveMessage.bind(this),
	};

	async handleMessagingSessionDidReceiveMessage(message: Message) {
		console.log("Messaging Connection received message", "");
		this.publishMessageUpdate(message);
	}

	setMessagingEndpoint(arn: string) {
		fetch("/api/endpoint")
			.then(async (response) => {
				this._result = await response.json()
				const sessionConfig = new MessagingSessionConfiguration(
					createMemberArn(arn),
					this.sessionId,
					undefined,
					new ChimeSDKMessaging({
						region: 'us-east-1',
						credentials: {
							accessKeyId: this._result.AccessKeyId,
							secretAccessKey: this._result.SecretAccessKey,
							sessionToken: this._result.SessionToken
						}
					}),
				);
				this._session = new DefaultMessagingSession(
					sessionConfig,
					this._logger
				);
				this._session.addObserver(this.messageObserver);
				this._session.start();
			})
			.catch((err) => {
				console.error("Failed to set messaging endpoint", err);
			});
	}

	async connect(arn: string) {
		this.setMessagingEndpoint(arn);
	}

	close() {
		try {
			this._session.stop();
		} catch (err) {
			console.error("Failed to stop Messaging Session.", err);
		}
	}

	subscribeToMessageUpdate(callback: any) {
		console.log("Message listener subscribed!", "");
		this._messageUpdateCallbacks.push(callback);
	}

	unsubscribeFromMessageUpdate(callback: any) {
		const index = this._messageUpdateCallbacks.indexOf(callback);
		if (index !== -1) {
			this._messageUpdateCallbacks.splice(index, 1);
		}
	}

	publishMessageUpdate(message: any) {
		console.log(`Sending message update to listeners!`, "");
		for (let i = 0; i < this._messageUpdateCallbacks.length; i += 1) {
			const callback = this._messageUpdateCallbacks[i];
			callback(message);
		}
	}
}

export default MessagingService;
