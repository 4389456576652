/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const describeAppInstanceUser = /* GraphQL */ `
	query DescribeAppInstanceUser($callerId: String!) {
		describeAppInstanceUser(callerId: $callerId) {
			appInstanceUser {
				AppInstanceUserArn
				Metadata
				Name
			}
			errored
			message
		}
	}
`;

export const getBusiness = `query GetBusiness($userId: String!){
  getUserProfile(userId: $userId) {
      business {
          name
          description
          email
          phone
          website
          payments
          languages
          address {
            state
            country
            city
          }
      }
  }
}`;

export const describeChannel = /* GraphQL */ `
	query DescribeChannel(
		$callerId: String!
		$channelArn: String!
		$withMessages: Boolean
	) {
		describeChannel(
			callerId: $callerId
			channelArn: $channelArn
			withMessages: $withMessages
		) {
			channel {
				ChannelArn
				ChannelFlowArn
				CreatedBy {
					Arn
					Name
				}
				CreatedTimestamp
				LastMessageTimestamp
				LastUpdatedTimestamp
				Metadata
				Mode
				Name
				Privacy
			}
			messages {
				ChannelArn
				Content
				CreatedTimestamp
				LastEditedTimestamp
				LastUpdatedTimestamp
				MessageId
				Metadata
				Persistence
				Redacted
				Sender {
					Arn
					Name
				}
				Status {
					Detail
					Value
				}
				Type
			}
			errored
			message
		}
	}
`;
export const describeChannelFlow = /* GraphQL */ `
	query DescribeChannelFlow($channelFlowArn: String!) {
		describeChannelFlow(channelFlowArn: $channelFlowArn) {
			channelFlow {
				ChannelFlowArn
				CreatedTimestamp
				LastUpdatedTimestamp
				Name
			}
			errored
			message
		}
	}
`;
export const findUser = /* GraphQL */ `
	query FindUser($id: String) {
		findUser(id: $id) {
			dateCreated
			email
			firstName
			id
			lastName
			reasonsReported
			status
			subscription
			country
		}
	}
`;

export const getAbout = `query GetAbout($userId: String!){
  getUserProfile(userId: $userId) {
      skillCategories {
          about
      }
  }
}`;

export const getEducation = `query GetEducation($userId: String!, $skillId: String!){
  getSkillCategory(userId: $userId, skillId: $skillId) {
      education {
          startDate
          institution
          fieldOfStudy
          endDate
          degree
      }

  }
}`;

export const getCertification = `query GetCertification($userId: String!, $skillId: String!){
  getSkillCategory(userId: $userId, skillId: $skillId) {
      certification {
          endDate
          dateIssued
          institution
          name
      }
  }
}`;
export const getAdminUsers = /* GraphQL */ `
	query GetAdminUsers {
		getAdminUsers {
			dateCreated
			email
			firstName
			id
			lastName
			reasonsReported
			status
			subscription
			country
		}
	}
`;
export const getAllPromotionalVideos = /* GraphQL */ `
	query GetAllPromotionalVideos($userId: String) {
		getAllPromotionalVideos(userId: $userId) {
			description
			id
			language
			location
			tags
			thumbnail
			title
			type
			user
			watchCount
			featured
			Status
			likes
			dislikes
		}
	}
`;
export const search = /* GraphQL */ `
	query Search(
		$searchTerm: String!
		$index: String!
		$location: String
		$next: Int
	) {
		search(
			searchTerm: $searchTerm
			index: $index
			location: $location
			next: $next
		) {
			users {
				business {
					address {
						city
						country
						state
					}
					name
					description
					email
					payments
					phone
					website
					languages
				}
				country
				createdAt
				email
				firstName
				lastName
				picLocation
				status
				tier {
					startDate
					type
				}
				userId
				onlineStatus
				inMeeting
				featured
				endpointId
			}
			skillVideos {
				description
				id
				language
				location
				tags
				thumbnail
				title
				type
				user
				watchCount
				featured
				Status
				likes
				dislikes
				preview
			}
			prodVideos {
				description
				id
				language
				location
				tags
				thumbnail
				title
				type
				user
				watchCount
				featured
				Status
				business {
					address {
						city
						country
						state
					}
					name
					description
					email
					payments
					phone
					website
					languages
				}
				preview
			}
		}
	}
`;
export const getPromoVideo = `query GetPromotionalVideo($videoId: String!, $userId: String){
  getPromotionalVideo(videoId: $videoId, userId: $userId) {
      location
      thumbnail
      title
      watchCount
      preview
      }
  }`;

export const getPromoVideoInfo = `query GetPromotionalVideo($videoId: String!){
    getPromotionalVideo(videoId: $videoId) {
      id
      user
      title
      description
      tags
      language
      location
      thumbnail
      watchCount
      featured
      type
      likes
      dislikes
      preview
        }
    }`;

export const getPromoVideoPreview = `query GetPromotionalVideo($videoId: String!){
  getPromotionalVideo(videoId: $videoId) {
      thumbnail
      title
      watchCount
      id
      preview
      }
  }`;
export const getLikedVideo = /* GraphQL */ `
	query GetLikedVideo($userId: String!, $videoId: String!) {
		getLikedVideo(userId: $userId, videoId: $videoId)
	}
`;
export const getDislikedVideo = /* GraphQL */ `
	query GetDislikedVideo($userId: String!, $videoId: String!) {
		getDislikedVideo(userId: $userId, videoId: $videoId)
	}
`;
export const getTransactionsByDate = /* GraphQL */ `
	query GetTransactionsByDate($start_date: String!, $end_date: String!) {
		getTransactionsByDate(start_date: $start_date, end_date: $end_date) {
			amount
			currency_code
			description
			id
			init_datetime
			payer_id
			payer_name
			plan_id
			status
			updated_datetime
			country
		}
	}
`;
export const getAllReportedVideos = /* GraphQL */ `
	query GetAllReportedVideos($lastKey: LastKeyInput) {
		getAllReportedVideos(lastKey: $lastKey) {
			lastKey {
				gsi2pk
				gsi2sk
				pk
				sk
			}
			videos {
				ReasonsReported
				Status
				dateReported
				description
				id
				language
				location
				tags
				thumbnail
				title
				user
			}
		}
	}
`;
export const getAllTransactions = /* GraphQL */ `
	query GetAllTransactions {
		getAllTransactions {
			amount
			currency_code
			description
			id
			init_datetime
			payer_id
			payer_name
			plan_id
			status
			updated_datetime
			country
		}
	}
`;
export const getUserTransactions = /* GraphQL */ `
	query GetUserTransactions($userId: String!) {
		getUserTransactions(userId: $userId) {
			amount
			currency_code
			description
			id
			init_datetime
			payer_id
			payer_name
			plan_id
			status
			updated_datetime
		}
	}
`;
export const getChannelMessage = /* GraphQL */ `
	query GetChannelMessage(
		$callerId: String!
		$channelArn: String!
		$messageId: String!
	) {
		getChannelMessage(
			callerId: $callerId
			channelArn: $channelArn
			messageId: $messageId
		) {
			message {
				ChannelArn
				Content
				CreatedTimestamp
				LastEditedTimestamp
				LastUpdatedTimestamp
				MessageId
				Metadata
				Persistence
				Redacted
				Sender {
					Arn
					Name
				}
				Status {
					Detail
					Value
				}
				Type
			}
		}
	}
`;
export const getCountFromSpecificSkillCategoryAndDateRange = /* GraphQL */ `
	query GetCountFromSpecificSkillCategoryAndDateRange(
		$endDate: String!
		$location: String
		$skillName: String!
		$startDate: String!
	) {
		getCountFromSpecificSkillCategoryAndDateRange(
			endDate: $endDate
			location: $location
			skillName: $skillName
			startDate: $startDate
		) {
			UserCount
		}
	}
`;

export const getVideoByCategory = `query GetVideosByCategory($category: String!, $next: Int){
  getVideosByCategory(category: $category, next: $next){
    thumbnail
    title
    watchCount
    id
    tags
    location
    preview
  }
}`;
export const getCountFromSpecificTierAndDateRange = /* GraphQL */ `
	query GetCountFromSpecificTierAndDateRange(
		$endDate: String!
		$location: String
		$startDate: String!
		$tierType: String!
	) {
		getCountFromSpecificTierAndDateRange(
			endDate: $endDate
			location: $location
			startDate: $startDate
			tierType: $tierType
		) {
			UserCount
		}
	}
`;
export const getMessagingSessionEndpoint = /* GraphQL */ `
	query GetMessagingSessionEndpoint {
		getMessagingSessionEndpoint {
			endpoint
		}
	}
`;
export const getPaymentDueUsers = /* GraphQL */ `
	query GetPaymentDueUsers($lastKey: LastKeyInput) {
		getPaymentDueUsers(lastKey: $lastKey) {
			lastKey {
				gsi2pk
				gsi2sk
				pk
				sk
			}
			users {
				dateCreated
				email
				firstName
				id
				lastName
				reasonsReported
				status
				subscription
				country
			}
		}
	}
`;
export const getPromotionalVideo = /* GraphQL */ `
	query GetPromotionalVideo($userId: String, $videoId: String!) {
		getPromotionalVideo(userId: $userId, videoId: $videoId) {
			description
			id
			language
			location
			tags
			thumbnail
			title
			type
			user
			watchCount
			featured
			Status
			likes
			dislikes
		}
	}
`;
export const getReportedUsers = /* GraphQL */ `
	query GetReportedUsers($lastKey: LastKeyInput) {
		getReportedUsers(lastKey: $lastKey) {
			lastKey {
				gsi2pk
				gsi2sk
				pk
				sk
			}
			users {
				dateCreated
				email
				firstName
				id
				lastName
				reasonsReported
				status
				subscription
				country
			}
		}
	}
`;
export const getReportedVideo = /* GraphQL */ `
	query GetReportedVideo($id: String) {
		getReportedVideo(id: $id) {
			ReasonsReported
			Status
			dateReported
			description
			id
			language
			location
			tags
			thumbnail
			title
			user
		}
	}
`;
export const getReportedVideosByCategory = /* GraphQL */ `
	query GetReportedVideosByCategory(
		$category: String
		$lastKey: LastKeyInput
	) {
		getReportedVideosByCategory(category: $category, lastKey: $lastKey) {
			lastKey {
				gsi2pk
				gsi2sk
				pk
				sk
			}
			videos {
				ReasonsReported
				Status
				dateReported
				description
				id
				language
				location
				tags
				thumbnail
				title
				user
			}
		}
	}
`;
export const getUserStatus = `query GetUserStatus($userId: String!){
  getUserProfile(userId: $userId) {
      status
  }
}`;

export const getUserInMeetingStatus = `query GetUserInMeetingStatus($userId: String!){
  getUserProfile(userId: $userId) {
      inMeeting
  }
}`;

export const getUserSummary = `query GetUserSummary($userId: String!){
  getUserProfile(userId: $userId) {
      tier{
        type
      }
      firstName
      lastName
      email
      userId
      picLocation
      country
    }
  }`;

export const getUserSkillSummary = `query GetUserSkillSummary($userId: String!){
    getUserSkills(userId: $userId) {
            name
      }
    }`;

export const getUserTier = `query GetUserTier($userId: String!){
      getUserProfile(userId: $userId) {
          tier{
            type
            startDate
          }
          createdAt
          userId
        }
      }`;

export const getUserSkills = /* GraphQL */ `
	query GetUserSkills($userId: String!) {
		getUserSkills(userId: $userId) {
			about {
				about
				currency_code
				halfAnHour
				hour
			}
			awards
			categories
			certification {
				credentialID
				credentialUrl
				dateIssued
				endDate
				institution
				name
			}
			education {
				degree
				endDate
				fieldOfStudy
				institution
				startDate
			}
			id
			name
		}
	}
`;

export const getSkillCategories = `query GetUserSkills($userId: String!){
  getUserSkills(userId: $userId) {
          id
          name
          categories
          awards
          about{
              about
              currency_code
              hour
              halfAnHour
          }
          education {
            startDate
            institution
            fieldOfStudy
            endDate
            degree
          }
          certification {
            credentialID
			credentialUrl
            endDate
            dateIssued
            institution
            name
          }

  }
}`;

export const GetAllReportedVideos = `query GetAllReportedVideos($lastKey: LastKeyInput){
  getAllReportedVideos(lastKey: $lastKey) {
    lastKey {
      pk
      sk
      gsi2pk
      gsi2sk
    }
    videos {
      id
      user
      title
      description
      location
      thumbnail
      tags
      language
      Status
      ReasonsReported
      dateReported
    }
  }
}`;
export const getSkillCategory = /* GraphQL */ `
	query GetSkillCategory($skillId: String!, $userId: String!) {
		getSkillCategory(skillId: $skillId, userId: $userId) {
			about {
				about
				currency_code
				halfAnHour
				hour
			}
			awards
			categories
			certification {
				credentialID
				credentialUrl
				dateIssued
				endDate
				institution
				name
			}
			education {
				degree
				endDate
				fieldOfStudy
				institution
				startDate
			}
			id
			name
		}
	}
`;
export const getUploadAttachmentSignedUrl = /* GraphQL */ `
	query GetUploadAttachmentSignedUrl($key: String!) {
		getUploadAttachmentSignedUrl(key: $key) {
			key
			url
			message
		}
	}
`;
export const getDownloadAttachmentSignedUrl = /* GraphQL */ `
	query GetDownloadAttachmentSignedUrl($key: String!) {
		getDownloadAttachmentSignedUrl(key: $key) {
			key
			url
			message
		}
	}
`;
export const getThumbnailSignedUrl = /* GraphQL */ `
	query GetThumbnailSignedUrl($key: String!) {
		getThumbnailSignedUrl(key: $key) {
			key
			url
			message
		}
	}
`;

export const getSearchSuggestions = /* GraphQL */ `
  query GetSearchSuggestions {
    getSearchSuggestions {
      key
      url
      message
    }
  }
`;
export const getSkillCSV = /* GraphQL */ `
  query GetSkillCSV {
    getSkillCSV {
      key
      url
      message
    }
  }
`;

export const getPictureSignedUrl = /* GraphQL */ `
  query GetPictureSignedUrl($key: String!) {
    getPictureSignedUrl(key: $key) {
      key
      url
      message
    }
  }
`;

export const getUserMeetings = /* GraphQL */ `
	query GetUserMeetings($userId: String!) {
		getUserMeetings(userId: $userId) {
			meetingId
			owner
			status
		}
	}
`;
export const getMeeting = /* GraphQL */ `
	query GetMeeting($meetingId: String!) {
		getMeeting(meetingId: $meetingId) {
			attendees
			date
			meetingId
			name
			status
			mediaPipelineId
		}
	}
`;

export const getUserPromoVideos = `query GetAllPromotionalVideos($userId:String){
  getAllPromotionalVideos(userId:$userId) {
      id
      title
      thumbnail
      tags
      description
      type
      watchCount
      featured
      Status
      location
      preview
      }
  }`;

export const getUserProfile = /* GraphQL */ `
	query GetUserProfile($userId: String!) {
		getUserProfile(userId: $userId) {
			business {
				address {
					city
					country
					state
				}
				name
				description
				email
				payments
				phone
				website
				languages
			}
			country
			createdAt
			email
			firstName
			lastName
			picLocation
			status
			tier {
				startDate
				type
			}
			userId
			onlineStatus
			inMeeting
			featured
			endpointId
		}
	}
`;
export const getUserReviews = /* GraphQL */ `
	query GetUserReviews($userId: String!) {
		getUserReviews(userId: $userId) {
			id
			date
			postBy
			rating
			review
			reply
		}
	}
`;
export const getVideosByCategory = /* GraphQL */ `
	query GetVideosByCategory($category: String, $next: Int) {
		getVideosByCategory(category: $category, next: $next) {
			description
			id
			language
			location
			tags
			thumbnail
			title
			type
			user
			watchCount
			featured
			Status
			likes
			dislikes
		}
	}
`;
export const getVideoPresignedUrl = /* GraphQL */ `
	query GetVideoPresignedUrl($userId: String!, $fileName: String!) {
		getVideoPresignedUrl(userId: $userId, fileName: $fileName) {
			url
			key
			videoId
		}
	}
`;
export const getUserIdByIdentity = /* GraphQL */ `
	query GetUserIdByIdentity($identityId: String!) {
		getUserIdByIdentity(identityId: $identityId)
	}
`;
export const listAppInstanceUsers = /* GraphQL */ `
	query ListAppInstanceUsers($nextToken: String) {
		listAppInstanceUsers(nextToken: $nextToken) {
			errored
			message
			users {
				AppInstanceUserArn
				Metadata
				Name
			}
		}
	}
`;
export const listChannelBans = /* GraphQL */ `
	query ListChannelBans($input: ListChannelBansInput) {
		listChannelBans(input: $input) {
			bans {
				Member {
					Arn
					Name
				}
			}
			errored
			message
			nextToken
		}
	}
`;
export const listChannelFlows = /* GraphQL */ `
	query ListChannelFlows($maxResult: Int, $nextToken: String) {
		listChannelFlows(maxResult: $maxResult, nextToken: $nextToken) {
			channelFlows {
				ChannelFlowArn
				CreatedTimestamp
				LastUpdatedTimestamp
				Name
			}
			errored
			message
		}
	}
`;
export const listChannelMemberships = /* GraphQL */ `
	query ListChannelMemberships($callerId: String!, $channelArn: String!) {
		listChannelMemberships(callerId: $callerId, channelArn: $channelArn) {
			errored
			memberships {
				Member {
					Arn
					Name
				}
			}
			message
		}
	}
`;
export const listChannelMembershipsForAppInstanceUser = /* GraphQL */ `
	query ListChannelMembershipsForAppInstanceUser($callerId: String!) {
		listChannelMembershipsForAppInstanceUser(callerId: $callerId) {
			errored
			memberships {
				AppInstanceUserMembershipSummary {
					ReadMarkerTimestamp
					Type
				}
				ChannelSummary {
					ChannelArn
					ChannelFlowArn
					CreatedBy {
						Arn
						Name
					}
					CreatedTimestamp
					LastMessageTimestamp
					LastUpdatedTimestamp
					Metadata
					Mode
					Name
					Privacy
				}
			}
			message
			nextToken
		}
	}
`;
export const listChannelMessages = /* GraphQL */ `
	query ListChannelMessages(
		$callerId: String!
		$channelArn: String!
		$nextToken: String
	) {
		listChannelMessages(
			callerId: $callerId
			channelArn: $channelArn
			nextToken: $nextToken
		) {
			errored
			message
			messages {
				ChannelArn
				Content
				CreatedTimestamp
				LastEditedTimestamp
				LastUpdatedTimestamp
				MessageId
				Metadata
				Persistence
				Redacted
				Sender {
					Arn
					Name
				}
				Status {
					Detail
					Value
				}
				Type
			}
			nextToken
		}
	}
`;
export const listChannelModerators = /* GraphQL */ `
	query ListChannelModerators($callerId: String!, $channelArn: String!) {
		listChannelModerators(callerId: $callerId, channelArn: $channelArn) {
			errored
			message
			moderators {
				Moderator {
					Arn
					Name
				}
			}
		}
	}
`;
export const listChannels = /* GraphQL */ `
	query ListChannels($callerId: String!, $nextToken: String) {
		listChannels(callerId: $callerId, nextToken: $nextToken) {
			channels {
				ChannelArn
				ChannelFlowArn
				CreatedBy {
					Arn
					Name
				}
				CreatedTimestamp
				LastMessageTimestamp
				LastUpdatedTimestamp
				Metadata
				Mode
				Name
				Privacy
			}
			errored
			message
			nextToken
		}
	}
`;
export const listUsers = /* GraphQL */ `
	query ListUsers($limit: Int) {
		listUsers(limit: $limit) {
			Attributes {
				Name
				Value
			}
			Enabled
			MfaOptions {
				AttributeName
				DeleiveryMedium
			}
			UserCreateDate
			UserLastModifiedDate
			UserStatus
			Username
		}
	}
`;
export const userSearch = /* GraphQL */ `
	query UserSearch($name: String!) {
		userSearch(name: $name) {
			Attributes {
				Name
				Value
			}
			Enabled
			MfaOptions {
				AttributeName
				DeleiveryMedium
			}
			UserCreateDate
			UserLastModifiedDate
			UserStatus
			Username
		}
	}
`;
export const listUserChannels = /* GraphQL */ `
	query ListUserChannels($userIdentity: String!) {
		listUserChannels(userIdentity: $userIdentity) {
			errored
			message
			channels {
				channelArn
				channelId
				channelName
				member
				owner
				isOwner
			}
			lastMessage {
				channelArn
				lastMessage {
					ChannelArn
					Content
					CreatedTimestamp
					LastEditedTimestamp
					LastUpdatedTimestamp
					MessageId
					Metadata
					Persistence
					Redacted
					Sender {
						Arn
						Name
					}
					Status {
						Detail
						Value
					}
					Type
				}
			}
		}
	}
`;
export const listAppInstanceUserEndpoints = /* GraphQL */ `
	query ListAppInstanceUserEndpoints($userId: String!) {
		listAppInstanceUserEndpoints(userId: $userId) {
			errored
			message
			endpoints {
				allowed
				endpointState {
					status
					reason
				}
				endpointId
				deviceToken
			}
		}
	}
`;

export const getAllActiveVideos = /* GraphQL */ `
	query GetAllActiveVideos {
		getAllActiveVideos
	}
`;

export const getAllActiveUsers = /* GraphQL */ `
	query GetAllActiveUsers {
		getAllActiveUsers
	}
`;


export const getActiveUsers = /* GraphQL */ `
	query GetActiveUsers($lastKey: LastKeyInput) {
		getActiveUsers(lastKey: $lastKey) {
			lastKey {
				gsi2pk
				gsi2sk
				pk
				sk
			}
			users {
				dateCreated
				email
				firstName
				id
				lastName
				reasonsReported
				status
				subscription
				country
			}
		}
	}
`;
