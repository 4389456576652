import { env } from "@/env.mjs";
import {
	CreateAttendeeMutationVariables,
	CreateMeetingMutation,
	CreateMeetingMutationVariables,
	DescribeChannelQueryVariables,
	DescribeChannelResult,
	EndMeetingMutationVariables,
	EndMeetingResult,
	GetChannelMessageQueryVariables,
	GetChannelMessageResult,
	GetDownloadAttachmentSignedUrlQueryVariables,
	GetMeetingQuery,
	GetMeetingQueryVariables,
	GetMessagingSessionEndpointResult,
	GetUploadAttachmentSignedUrlQueryVariables,
	JoinMeetingMutationVariables,
	JoinMeetingResult,
	ListChannelMembershipsForAppInstanceUserQueryVariables,
	ListChannelMembershipsForAppInstanceUserResult,
	ListChannelMembershipsQueryVariables,
	ListChannelMembershipsResult,
	ListChannelMessagesQueryVariables,
	ListChannelMessagesResult,
	ListChannelModeratorsQueryVariables,
	ListChannelModeratorsResult,
	ListUserChannelResult,
	ListUserChannelsQueryVariables,
	RedactChannelMessageMutationVariables,
	RedactChannelMessageResult,
	SaveMeetingInfoMutation,
	SaveMeetingInfoMutationVariables,
	SaveUserMeetingMutationVariables,
	SaveUserMeetingResult,
	SendChannelMessageInput,
	SendChannelMessageOptionInput,
	SendChannelMessageResult,
	SignedUrlResult,
	StartChatMutation,
	StartChatMutationVariables,
	StartChatResult,
	StartRecordingMutation,
	StartRecordingMutationVariables,
	UpdateChannelMessageMutationVariables,
	UpdateChannelMessageResult,
	UpdateChannelMutation,
	UpdateChannelMutationVariables,
	UpdateChannelResult,
	UpdateMeetingPipelineIdMutation,
	UpdateMeetingPipelineIdMutationVariables,
	UpdateMeetingStatusMutation,
	UsersInput,
} from "@/graphql/API";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import { ChimeSDKMessaging } from "@aws-sdk/client-chime-sdk-messaging";
import { MessagingSessionConfiguration } from "amazon-chime-sdk-js";
import { AttendeeResponse } from "amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types";

export const createMemberArn = (userId: string) =>
	`${env.NEXT_PUBLIC_APP_INSTANCE_ARN}/user/${userId}`;

export const Persistence = {
	PERSISTENT: "PERSISTENT",
	NON_PERSISTENT: "NON_PERSISTENT",
};

export const MessageType = {
	STANDARD: "STANDARD",
	CONTROL: "CONTROL",
};

export async function getMessagingSessionEndpoint() {
	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,
			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: queries.getMessagingSessionEndpoint,
		}),
	});

	let response: {
		data: { getMessagingSessionEndpoint: GetMessagingSessionEndpointResult };
	} = await results.json();
	return response.data.getMessagingSessionEndpoint;
}

export async function configureSession(id: string) {
	let response = await getMessagingSessionEndpoint();
	let sessionConfig = new MessagingSessionConfiguration(
		createMemberArn(id),
		null,
		response.endpoint,
		new ChimeSDKMessaging({ region: "us-east-1" })
	);
	return sessionConfig;
}

export async function sendChannelMessage(params: {
	channelArn: string;
	messageContent: string;
	persistence: "PERSISTENT" | "NON_PERSISTENT";
	type: "STANDARD" | "CONTROL";
	member: any;
	options?: SendChannelMessageOptionInput | undefined;
}) {
	console.log("sendChannelMessage called", params);
	const variables: SendChannelMessageInput = {
		callerId: params.member.arn,
		channelArn: params.channelArn,
		messageContent: params.messageContent,
		persistence: params.persistence,
		type: params.type,
		options: params.options,
	};

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: mutations.sendChannelMessage,
			variables: { input: variables },
		}),
	});

	let response: { data: { sendChannelMessage: SendChannelMessageResult } } =
		await results.json();
	console.log("response", response);

	const sentMessage = {
		response: response.data.sendChannelMessage,
		CreatedTimestamp: new Date(),
		Sender: {
			Arn: createMemberArn(params.member.arn),
			Name: params.member.Name,
		},
	};

	return sentMessage;
}

export async function listChannelMessages(
	channelArn: any,
	userId: string,
	nextToken: string | undefined
) {
	console.log("listChannelMessages called", "");

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: queries.listChannelMessages,
			variables: {
				callerId: userId,
				channelArn,
				nextToken,
			} as ListChannelMessagesQueryVariables,
		}),
	});

	let response: {
		data: {
			listChannelMessages: ListChannelMessagesResult;
		};
	} = await results.json();
	console.log(
		`channel messages for ${channelArn}`,
		response.data.listChannelMessages.messages
	);

	return {
		Messages: response.data.listChannelMessages.messages,
		NextToken: response.data.listChannelMessages.nextToken,
	};
}

export async function describeChannel(
	channelArn: string,
	userId: string,
	withMessages?: boolean | null | undefined
) {
	console.log("describeChannel called", "");

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: queries.describeChannel,
			variables: {
				channelArn,
				callerId: userId,
				withMessages,
			} as DescribeChannelQueryVariables,
		}),
	});

	let response: { data: { describeChannel: DescribeChannelResult } } =
		await results.json();

	return response.data.describeChannel;
}

export async function listChannelModerators(
	channelArn: string,
	userId: string
) {
	console.log("listChannelModerators called", "");

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: queries.listChannelModerators,
			variables: {
				channelArn,
				callerId: userId,
			} as ListChannelModeratorsQueryVariables,
		}),
	});

	let response: {
		data: { listChannelModerators: ListChannelModeratorsResult };
	} = await results.json();

	return response.data.listChannelModerators.moderators;
}

export async function updateMeetingStatus(
	userId: string,
	meetingId: string,
	status: string
) {
	try {
		let results = await fetch(env.GRAPHQL_URL, {
			method: "POST",
			headers: {
				"x-api-key": env.API_KEY,

				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				query: mutations.updateMeetingStatus,
				variables: {
					userId: userId,
					meetingId: meetingId,
					status: status,
				},
			}),
		});

		let response: { data: UpdateMeetingStatusMutation } =
			await results.json();
		return response.data.updateMeetingStatus;
	} catch (error) {
		console.error("Failed to update meeting status", error as Error);
	}
}

export async function saveMeeting(date: string, meeting: any, attendees: any) {
	try {
		let results = await fetch(env.GRAPHQL_URL, {
			method: "POST",
			headers: {
				"x-api-key": env.API_KEY,

				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				query: mutations.saveMeetingInfo,
				variables: {
					meetingDate: date,
					meeting: meeting,
					attendees: attendees,
				},
			}),
		});

		let response: { data: SaveMeetingInfoMutation } = await results.json();
		return response.data.saveMeetingInfo;
	} catch (error) {
		console.error("Failed to save meeting info", error as Error);
	}
}

export async function endMeeting(
	meetingId: string,
	channelArn: string,
	callerId: string
) {
	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: mutations.endMeeting,
			variables: {
				meetingId,
				callerId,
				channelArn,
			} as EndMeetingMutationVariables,
		}),
	});

	let response: { data: { endMeeting: EndMeetingResult } } =
		await results.json();
	return response.data.endMeeting;
}

export async function getMeeting(meetingId: string) {
	try {
		let results = await fetch(env.GRAPHQL_URL, {
			method: "POST",
			headers: {
				"x-api-key": env.API_KEY,
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				query: queries.getMeeting,
				variables: {
					meetingId,
				} as GetMeetingQueryVariables,
			}),
		});
		let response: { data: GetMeetingQuery } = await results.json();
		return response.data.getMeeting;
	} catch (error) {
		console.error("Failed to get meeting", error as Error);
	}
}

export async function startRecording(meetingId: string) {
	try {
		let results = await fetch(env.GRAPHQL_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-api-key": env.API_KEY,
			},

			body: JSON.stringify({
				query: mutations.startRecording,
				variables: {
					meetingId,
				} as StartRecordingMutationVariables,
			}),
		});

		let response: { data: StartRecordingMutation } = await results.json();
		console.log("Start Recording", response);

		return response.data.startRecording;
	} catch (error) {
		console.error("Failed to start recording", error as Error);
		return "FAILED";
	}
}

export async function updateMeetingPipelineId(
	meetingId: string,
	mediaPipelineId: string
) {
	try {
		let results = await fetch(env.GRAPHQL_URL, {
			method: "POST",
			headers: {
				"x-api-key": env.API_KEY,
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				query: mutations.updateMeetingPipelineId,
				variables: {
					meetingId,
					mediaPipelineId,
				} as UpdateMeetingPipelineIdMutationVariables,
			}),
		});

		let response: { data: UpdateMeetingPipelineIdMutation } =
			await results.json();
		console.log("Update Meeting MediaPipeline ID", response);
		return response.data.updateMeetingPipelineId;
	} catch (error) {
		console.error("Failed to update meeting mediapipelineId", error as Error);
	}
}

export async function updateChannel(
	channelArn: string,
	name: string,
	mode: any,
	metadata: any,
	userId: string
) {
	console.log("updateChannel called", "");

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,
			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: mutations.updateChannel,
			variables: {
				input: {
					channelArn,
					name,
					mode,
					metadata,
					callerId: userId,
				},
			} as UpdateChannelMutationVariables,
		}),
	});

	let response: { data: { updateChannel: UpdateChannelResult } } =
		await results.json();

	return response.data.updateChannel;
}

async function listChannelMembershipsForAppInstanceUser(userId: string) {
	console.log("listChannelMembershipsForAppInstanceUser called", "");

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			query: queries.listChannelMembershipsForAppInstanceUser, variables: {
				callerId: userId,
			} as ListChannelMembershipsForAppInstanceUserQueryVariables,
		})
	})

	let response: {
		data: {
			listChannelMembershipsForAppInstanceUser: ListChannelMembershipsForAppInstanceUserResult;
		}
	} = await results.json()


	const channels =
		response.data.listChannelMembershipsForAppInstanceUser.memberships;
	return channels;
}

export async function listUserChannels(userIdentity: string) {
	console.log("listUserChannels called", userIdentity);

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,
			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: queries.listUserChannels,
			variables: {
				userIdentity: userIdentity,
			} as ListUserChannelsQueryVariables,
		}),
	});

	let response: {
		data: {
			listUserChannels: ListUserChannelResult;
		};
	} = await results.json();
	console.log("response", response);

	return response.data.listUserChannels;
}

export async function listChannelMemberships(
	channelArn: string,
	userId: string
) {
	console.log("listChannelMemberships called", "");

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,
			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: queries.listChannelMemberships,
			variables: {
				channelArn,
				callerId: userId,
			} as ListChannelMembershipsQueryVariables,
		}),
	});

	let response: {
		data: { listChannelMemberships: ListChannelMembershipsResult };
	} = await results.json();
	console.log(response);

	return response.data.listChannelMemberships.memberships;
}

export function createGetAttendeeCallback() {
	return async (
		chimeAttendeeId: string,
		externalUserId: string | undefined
	): Promise<AttendeeResponse> => {
		let attendeeName = externalUserId;
		if (!externalUserId?.includes("MediaPipeline")) {
			const attendeeNameTemplate = externalUserId?.split("#")[1];
			attendeeName = attendeeNameTemplate?.replace("_", " ");
		}
		return {
			name: attendeeName,
		};
	};
}

export async function joinMeetingRequest(params: {
	attendeeId: string;
	callerId: string;
	channelArn: string;
	channelName: string;
	meetingId?: string;
	meetingOwner?: boolean;
}) {
	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: mutations.joinMeeting,
			variables: {
				input: {
					attendeeId: params.attendeeId,
					callerId: params.callerId,
					channel: {
						channelArn: params.channelArn,
						channelName: params.channelName,
					},
					meetingId: params.meetingId,
					isMeetingOwner: params.meetingOwner ?? false,
				},
			} as JoinMeetingMutationVariables,
		}),
	});

	let response: { data: { joinMeeting: JoinMeetingResult } } =
		await results.json();

	return response.data.joinMeeting;
}

export async function createMeeting(
	name: string,
	meetingName: string,
	userId: string,
	channelArn: string
) {
	try {
		let results = await fetch(env.GRAPHQL_URL, {
			method: "POST",
			headers: {
				"x-api-key": env.API_KEY,

				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				query: mutations.createMeeting,
				variables: {
					userId: userId,
					meetingName: meetingName,
					name: name,
					channel: channelArn,
				} as CreateMeetingMutationVariables,
			}),
		});

		let response: { data: CreateMeetingMutation } = await results.json();
		return response.data.createMeeting;
	} catch (error) {
		console.error("Failed to create meeting", error as Error);
	}
}

export async function createAttendee(
	name: string,
	userId: string,
	channelArn: string,
	meeting: any
) {
	try {
		let results = await fetch(env.GRAPHQL_URL, {
			method: "POST",
			headers: {
				"x-api-key": env.API_KEY,

				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				query: mutations.createAttendee,
				variables: {
					name,
					userId,
					channel: channelArn,
					meeting,
				} as CreateAttendeeMutationVariables,
			}),
		});

		let response: { data: CreateMeetingMutation } = await results.json();
		return response.data.createMeeting;
	} catch (e) {
		throw e;
	}
}

export async function saveUserMeeting(
	userId: string,
	meetingId: string,
	status: string,
	owner: boolean
) {
	try {
		let results = await fetch(env.GRAPHQL_URL, {
			method: "POST",
			headers: {
				"x-api-key": env.API_KEY,

				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				query: mutations.saveUserMeeting,
				variables: {
					userId,
					meetingId,
					status,
					owner,
				} as SaveUserMeetingMutationVariables,
			}),
		});
		let response: { data: { saveUserMeeting: SaveUserMeetingResult } } =
			await results.json();
		return response.data.saveUserMeeting;
	} catch (error) {
		console.error("Failed to save user meeting", error as Error);
	}
}

export async function updateChannelMessage(
	channelArn: string,
	messageId: string,
	content: any,
	metadata: any,
	userId: string
) {
	console.log("updateChannelMessage called", "");

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: mutations.updateChannelMessage,
			variables: {
				input: {
					channelArn,
					content,
					messageId,
					metadata,
					callerId: userId,
				},
			} as UpdateChannelMessageMutationVariables,
		}),
	});

	let response: {
		data: { updateChannelMessage: UpdateChannelMessageResult };
	} = await results.json();

	return response.data.updateChannelMessage;
}

export async function redactChannelMessage(
	channelArn: string,
	messageId: string,
	userId: string
) {
	console.log("redactChannelMessage called", "");

	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: mutations.redactChannelMessage,
			variables: {
				channelArn,
				messageId,
				callerId: userId,
			} as RedactChannelMessageMutationVariables,
		}),
	});

	let response: {
		data: { redactChannelMessage: RedactChannelMessageResult };
	} = await results.json();

	return response.data.redactChannelMessage;
}

export async function getChannelMessage(
	channelArn: any,
	userId: any,
	messageId: any
) {
	console.log("getChannelMessage called", "");
	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: queries.getChannelMessage,
			variables: {
				callerId: userId,
				channelArn,
				messageId,
			} as GetChannelMessageQueryVariables,
		}),
	});

	let response: { data: { getChannelMessage: GetChannelMessageResult } } =
		await results.json();
	return response.data.getChannelMessage.message;
}

export async function getUploadAttachmentUrl(key: string) {
	console.log("getUploadAttachmentUrl called", "");
	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: queries.getUploadAttachmentSignedUrl,
			variables: {
				key,
			} as GetUploadAttachmentSignedUrlQueryVariables,
		}),
	});

	let response: { data: { getUploadAttachmentSignedUrl: SignedUrlResult } } =
		await results.json();
	console.log("Upload Response", response);
	return response.data.getUploadAttachmentSignedUrl;
}

export async function getDownloadAttachmentUrl(key: string) {
	console.log("getDownloadAttachmentUrl called", "");
	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: queries.getDownloadAttachmentSignedUrl,
			variables: {
				key,
			} as GetDownloadAttachmentSignedUrlQueryVariables,
		}),
	});

	let response: { data: { getDownloadAttachmentSignedUrl: SignedUrlResult } } =
		await results.json();
	console.log("Upload Response", response);
	return response.data.getDownloadAttachmentSignedUrl;
}

export async function initiateChat(
	metadata: string | undefined,
	users: UsersInput
) {
	console.log("intiate chat called", "");
	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,

			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: mutations.startChat,
			variables: {
				metadata,
				users,
			} as StartChatMutationVariables,
		}),
	});

	let response: { data: { startChat: StartChatResult } } =
		await results.json();
	return response.data.startChat;
}

export async function getAWSCredential() {
	let results = await fetch(env.GRAPHQL_URL, {
		method: "POST",
		headers: {
			"x-api-key": env.API_KEY,
			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			query: `
      query GetAWSCredentials {
        getAWSCredentials {
          AccessKeyId
          SecretAccessKey
          SessionToken
          Expiration
        }
      }
    `,
		}),
	});

	let response = await results.json();
	return response.data.getAWSCredentials;
}
